import React from "react"
import { Header } from "../components/header/Header"
import "../components/fiche-produits/ficheProduit.css"
import { Footer } from "../components/footer/Footer"
import FicheProduitContenu from "../components/fiche-produits/FicheProduit"

const ProductPage = ({ location }) => {
  return (
    <div>
      <Header />
      <FicheProduitContenu
        id={
          typeof location.state === `undefined`
            ? "0"
            : location.state.params.id_produit
        }
        refSetem={
          typeof location.state === `undefined`
            ? "0"
            : location.state.params.refSetem
        }
        product={
          typeof location.state === `undefined`
            ? {}
            : location.state.params.product
        }
      />
      <Footer />
    </div>
  )
}
export default ProductPage
